@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #f4f4f6;
}

.hover\:before\:content-\[\'update\'\]:hover::before {
    --tw-content: 'update';
    content: var(--tw-content);
    position: absolute;
    color: white;
}

.opt-btn {
    width: 18px;
    height: 18px;
    appearance: none;
    position: relative;
    border-radius: 50%;
    border: 1px solid black;
    outline: none;
  }

  .opt-btn::before {
    content: "";
    position: absolute;
    top: 1.2px;
    left: 1.4px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
  }

  .opt-btn:checked::before {
    --tw-bg-opacity: 1;
    background-color: rgb(220 100 120 / var(--tw-bg-opacity));
  }

  .Toastify__toast--error {
    background-color: rgb(244, 233, 233) !important;
    color: black !important;
    z-index: 1 !important;
    font-size: 12px !important;
}

.hover\:before\:content-\[\'update\'\]:hover::before {
  --tw-content: 'update';
  content: var(--tw-content);
  background: #000000c4;
  width: 100%;
  text-align: center;
  bottom: 15px;
}
